var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';
export default function BreadcrumbsLink(_a) {
    var link = _a.link, activeLast = _a.activeLast, disabled = _a.disabled;
    var name = link.name, href = link.href, icon = link.icon;
    var styles = __assign({ typography: 'body2', alignItems: 'center', color: 'text.primary', display: 'inline-flex' }, (disabled &&
        !activeLast && {
        cursor: 'default',
        pointerEvents: 'none',
        color: 'text.disabled',
    }));
    var renderContent = (_jsxs(_Fragment, { children: [icon && (_jsx(Box, __assign({ component: "span", sx: {
                    mr: 1,
                    display: 'inherit',
                    '& svg': { width: 20, height: 20 },
                } }, { children: icon }))), _jsx(Box, __assign({ textTransform: "capitalize" }, { children: name }))] }));
    if (href) {
        return (_jsx(Link, __assign({ component: RouterLink, to: href, sx: styles }, { children: renderContent })));
    }
    return _jsxs(Box, __assign({ sx: styles }, { children: [" ", renderContent, " "] }));
}
